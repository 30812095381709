import { ServicesComponent } from './components/services/services.component';
import { AboutComponent } from './components/about/about.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { FeaturesComponent } from './components/features/features.component';
import { WhyWeDifferentComponent } from './components/why-we-different/why-we-different.component';
import { FunfactsComponent } from './components/funfacts/funfacts.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { FunfactsTwoComponent } from './components/funfacts-two/funfacts-two.component';
import { FaqComponent } from './components/faq/faq.component';
import { PartnerComponent } from './components/partner/partner.component';
import { CtaTwoComponent } from './components/cta-two/cta-two.component';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";

import { CommonModule, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeRo from '@angular/common/locales/ro';
import localeRoExtra from '@angular/common/locales/extra/ro';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { SimplePortfolioComponent } from './components/simple-portfolio/simple-portfolio.component';
import { TranslateHelper } from './shared/translate-helper';
import { ContactComponent } from './components/contact/contact.component';
import { CookiePolicyPageComponent } from './pages/cookie-policy-page/cookie-policy-page.component';
import { TermsConditionsPageComponent } from './pages/terms-conditions-page/terms-conditions-page.component';
import { PortfolioPageComponent } from './pages/portfolio-page/portfolio-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { EmailService } from './services/email.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotToastModule } from '@ngneat/hot-toast';
import { PortfolioDetailsPageComponent } from './pages/portfolio-details-page/portfolio-details-page.component';
import { ServicesDetailsPageComponent } from './pages/services-details-page/services-details-page.component';
import { CareerPageComponent } from './pages/career-page/career-page.component';
import { ImageTextComponent } from './components/image-text/image-text.component';
import { SlideImagesComponent } from './components/slide-images/slide-images.component';
import { CareerFormPageComponent } from './pages/career-form-page/career-form-page.component';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { ShopPageComponent } from './pages/shop-page/shop-page.component';
import { environment } from 'src/environments/environment';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { StructureInfoComponent } from './components/structure-info/structure-info.component';
import { GroupStructureComponent } from './components/group-structure/group-structure.component';
import { EmailService2 } from './services/emailjs.service';
import { VideoTextComponent } from './components/video-text/video-text.component';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeRo, 'ro', localeRoExtra);

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' }
  ]);
}

const recaptchaSettings: RecaptchaSettings = { siteKey: environment.recaptcha };

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain,
  },
  position: "bottom",
  palette: {
    popup: {
      background: '#ffff',
      text: "#000"
    },
    button: {
      background: '#A23E41'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};


@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    PreloaderComponent,
    NavbarComponent,
    FooterComponent,
    FeaturesComponent,
    FunfactsComponent,
    WhyWeDifferentComponent,
    PortfolioComponent,
    FunfactsTwoComponent,
    FaqComponent,
    PartnerComponent,
    CtaTwoComponent,
    SimplePortfolioComponent,
    ContactComponent,
    ServicesComponent,
    ImageTextComponent,
    SlideImagesComponent,
    ShopPageComponent,
    VideoTextComponent,
    
    HomePageComponent,
    ContactPageComponent,
    CookiePolicyPageComponent,
    TermsConditionsPageComponent,
    PortfolioPageComponent,
    ErrorPageComponent,
    HomePageComponent,
    AboutUsPageComponent,
    ServicesPageComponent,
    PortfolioDetailsPageComponent,
    ServicesDetailsPageComponent,
    CareerPageComponent,
    CareerFormPageComponent,
    StructureInfoComponent,
    GroupStructureComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AppRoutingModule,
    NgxScrollTopModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    
    NgcCookieConsentModule.forRoot(cookieConfig),

    HotToastModule.forRoot(),

    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  // exports: [TranslateHelper],
  providers: [
    TranslateHelper,
    EmailService,
    EmailService2,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: recaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['en', 'ro']);
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
