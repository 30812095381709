import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit {

  details: any[] = [];
  imageLeft: boolean = false;
  lightBackground: boolean = true;

  groupStructures: any[] = [];

  imageSlider: any[] = [];

  constructor(private translateHelper: TranslateHelper,
    private translate: TranslateService,
    private metaTagService: Meta) {

  }

  ngOnInit(): void {

    this.translate.get('meta.about_us').subscribe(values => {
      if (values) {
        const keys = Object.keys(values);
        if (keys.length > 0) {
          keys.forEach(key => {
            if (key.startsWith("og")) {
              this.metaTagService.updateTag({
                property: key,
                content: values[key]
              });
            } else {
              this.metaTagService.updateTag({
                name: key,
                content: values[key]
              });
            }
          })
        }
      }
    });    

    const images = this.translateHelper.getListOfNo('about_us.images.', '.title');
    images.forEach(image => {
      this.translate.get(`about_us.images.${image}.title`).subscribe(value => {
        this.imageSlider.push({
          url: `assets/img/about-img${image}.jpg`,
          title: value
        });
      });
    });

    this.details = this.translateHelper.getListOfNo('about_us.details.', '.description');

    let grStr = this.translateHelper.getListOfNo('about_us.groupStructures.', '.name') as any[];

    grStr.forEach((gs) => {
      // console.log("this.gs: ", gs);
      // console.log("this.index: ", index);
      let items = this.translateHelper.getListOfNo(`about_us.groupStructures.${gs}.items.`, '.name') ?? [];
      if (items && items.length > 0) {
        let itemsVal: any[] = [];
        items.forEach((item) => {
          // console.log(`about_us.groupStructures.${gs}.items.${item}.subitems.`);

          let subitems = this.translateHelper.getListOfNo(`about_us.groupStructures.${gs}.items.${item}.subitems.`, '.name') ?? [];
          let isDown = false;
          if (gs == 4 && item == 3) {
            isDown = true;
          }

          if (subitems && subitems.length > 0) {
            // this.groupStructures.push({ index: gs, items: { index: item, subitems: subitems } });
            itemsVal.push({ index: item, isDown: isDown, subitems: subitems });
          } else {
            // this.groupStructures.push({ index: gs, items: { index: item, subitems: [] } });
            itemsVal.push({ index: item, isDown: isDown, subitems: [] });
          }
        });

        this.groupStructures.push({ index: gs, items: itemsVal });
      }
    });

    // console.log("this.groupStructures: ", this.groupStructures);
  }

}