<section [class]="'page-title-area' + (('about_us.image' | translate).length> 0 ? ' '+('about_us.image' | translate) :'item-bg1')">
    <div class="container">
        <div class="section-title section-title-color">
            <span *ngIf="('about_us.quote' | translate).length> 1" [innerHtml]="'about_us.quote' | translate"></span>
            <span *ngIf="('about_us.quote' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'about_us.quote'}}</span>
            <h2 *ngIf="('about_us.title' | translate).length> 1" [innerHtml]="'about_us.title' | translate"></h2>
            <h2 *ngIf="('about_us.title' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'about_us.title'}}</h2>
            <!-- <p *ngIf="('about_us.paragraph_text' | translate).length> 1" [innerHtml]="'about_us.paragraph_text' | translate" [style]="('about_us.paragraph_text'
                | translate).length <66 ? 'margin-bottom: 60px': ''"></p>
                <p *ngIf="('about_us.paragraph_text' | translate).length <= 1" style="background-color: transparent !important; color: transparent;">{{'about_us.paragraph_text'}}</p> -->
            </div>
        </div>
    </section>

    <app-image-text
        [imageLeft]="false"
        [lightBackground]="true"
        [imagePath]="'assets/img/about-details-img'+details[0]+'.jpg'"
        [imageTitle]="'about_us.details.'+details[0]+'.imageTitle' | translate"
        [description]="'about_us.details.'+details[0]+'.description' | translate">
    </app-image-text>

    <app-group-structure 
        [lightBackground]="false"
        [groupStructures]="groupStructures">        
    </app-group-structure>    
        
    <app-image-text *ngFor="let detail of details.slice(1, -2)"    
        [imageLeft]="detail % 2 == 0 ? true : false"
        [lightBackground]="detail % 2 == 0 ? false : true"
        [imagePath]="'assets/img/about-details-img'+detail+'.jpg'"
        [imageTitle]="'about_us.details.'+detail+'.imageTitle' | translate"
        [description]="'about_us.details.'+detail+'.description' | translate">
    </app-image-text>

    <app-slide-images [images]="imageSlider"></app-slide-images>
    <!-- <app-about></app-about> -->

    <app-cta-two id="contact"></app-cta-two>