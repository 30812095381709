<section class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="icofont-info-circle"></i> <span>{{'contact.labels.info' | translate}}</span><a>{{'contact.company_info' |
                                        translate}}</a></li>
                                <li><i class="icofont-google-map"></i> <span>{{'contact.labels.location' | translate}}</span><a
                                        [href]="'https://www.google.com/maps/place/' + ('contact.address' | translate)" target="_blank">{{'contact.address' | translate}}</a></li>
                                <li><i class="icofont-envelope"></i> <span>{{'contact.labels.email' | translate}}</span><a [href]="'mailto:' + ('contact.email'
                                        | translate)" target="_blank">{{'contact.email' | translate}}</a></li>
                                <li><i class="icofont-phone"></i> <span>{{'contact.labels.phone' | translate}}</span><a [href]="'tel:' + ('contact.phone' |
                                        translate)">{{'contact.phone' | translate}}</a></li>
                                <!-- <li><i class="icofont-fax"></i> <span>Fax:</span><a [href]="'tel:' + ('contact.fax' | translate)">{{'contact.fax' | translate}}</a></li> -->
                                <!-- <li><i class="icofont-facebook"></i> <span>Facebook:</span><a href="https://facebook.com/" target="_blank">facebook.com/avurn</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form id="contactForm" [formGroup]="contactFormGroup" #contactForm="ngForm" (submit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" id="contactName" class="form-control" formControlName="name"
                                        placeholder="{{'contact.details.name.text' | translate}}*" required>
                                    <span class="text-danger" *ngIf="(contactFormControl.name.touched || submitted) &&
                                        contactFormControl.name.errors?.required">
                                        {{'contact.details.name.error' | translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" id="contactEmail" class="form-control" formControlName="email"
                                        placeholder="{{'contact.details.email.text' | translate}}*" required>
                                    <span class="text-danger" *ngIf="(contactFormControl.email.touched || submitted) &&
                                        contactFormControl.email.errors?.required">
                                        {{'contact.details.email.error' | translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" class="form-control" formControlName="company" placeholder="{{'contact.details.company' | translate}}">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea class="form-control" formControlName="message" cols="30" rows="5" placeholder="{{'contact.details.message' |
                                        translate}}"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input class="form-check-input checkbox" type="checkbox" id="contactTermsConditions" 
                                    formControlName="termsConditions" required>
                                    <label class="form-check-label" for="contactTermsConditions" [innerHtml]="'contact.details.terms_conditions.text' | translate">
                                    </label>
                                    <span class="text-danger" *ngIf="(contactFormControl.termsConditions.touched || submitted) &&
                                        contactFormControl.termsConditions.errors?.required && contactFormControl.termsConditions.status">
                                        {{'contact.details.terms_conditions.error' | translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <re-captcha formControlName="recaptcha" (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"
                                    formControlName="recaptcha" required></re-captcha>
                                    <span class="text-danger" *ngIf="(contactFormControl.recaptcha.touched || submitted) &&
                                        contactFormControl.recaptcha.errors?.required">
                                        {{'contact.details.recaptcha' | translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">{{'contact.details.button_send' | translate}}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>