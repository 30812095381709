import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-portfolio-page',
  templateUrl: './portfolio-page.component.html',
  styleUrls: ['./portfolio-page.component.scss']
})
export class PortfolioPageComponent implements OnInit {

  constructor(private translate: TranslateService,
    private metaTagService: Meta) {
  }

  ngOnInit(): void {

    this.translate.get('meta.portfolio').subscribe(values => {
      if (values) {
        const keys = Object.keys(values);
        if (keys.length > 0) {
          keys.forEach(key => {
            if (key.startsWith("og")) {
              this.metaTagService.updateTag({
                property: key,
                content: values[key]
              });
            } else {
              this.metaTagService.updateTag({
                name: key,
                content: values[key]
              });
            }
          })
        }
      }
    });        
  }

}
