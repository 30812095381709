<section id="about" class="about-area ptb-100 bg-image single-work">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="image">
                        <img src="assets/img/about-us-img1.png" alt="about" loading="lazy">
                    </div>

                    <!-- <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img1.jpg" alt="about">
                        </div>
                    </div> -->

                    <!-- <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img2.jpg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img3.jpg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img4.jpg" alt="about">
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content blog-details" [innerHtml]="'about_us.description' | translate">
                </div>

                <div class="mt-5 mx-auto d-flex flex-row flex-wrap justify-content-around owl-carousel">
                    <a href="assets/file/TRI-WALL_ROMANIA_9001.pdf" class="popup-btn-pdf">
                        <img src="assets/img/about-us-MC9001.png" width=100 alt="about" loading="lazy">
                    </a>

                    <a href="assets/file/TRI-WALL_ROMANIA_14001.pdf" class="popup-btn-pdf">
                        <img src="assets/img/about-us-MC14001.png" width=100 alt="about" loading="lazy">
                    </a>

                    <a href="assets/file/TRI-WALL_ROMANIA_FSC_C157580.pdf" class="popup-btn-pdf">
                        <img src="assets/img/about-us-FSC_C157580.png" width=100 alt="about" loading="lazy">
                    </a>
                                        
                    <div id="AFCT_membru" style="width: 100px;">                        
                    </div>

                </div>
            </div>
        </div>
    </div>

</section>