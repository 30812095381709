import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelper } from 'src/app/shared/translate-helper';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomePageComponent implements OnInit {

  banners: any[] = [];

  constructor(private translateHelper: TranslateHelper,
    private titleService: Title,
    private translate: TranslateService,
    private metaTagService: Meta) {
  }

  ngOnInit(): void {
    this.banners = this.translateHelper.getListOfNo('home.banners.', '.title');

    this.translate.get('meta.home').subscribe(values => {
      if (values) {
        const keys = Object.keys(values);
        if (keys.length > 0) {
          keys.forEach(key => {
            if (key.startsWith("og") && values[key] && values[key].length > 0) {
              this.metaTagService.addTag({
                property: key,
                content: values[key]
              });
            } else if (values[key] && values[key].length > 0) {
              if (key == 'title') {
                this.titleService.setTitle(values[key])
              }

              this.metaTagService.addTag({
                name: key,
                content: values[key]
              });
            }
          })
        }
      }
    });          
  }
}
