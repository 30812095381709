<section id="services" class="services-area ptb-100">
    <div class="container">
        <div class="row">

            <div class="col-lg-6 col-md-6" *ngFor="let service of services;">
                <div class="single-services">
                    <a [routerLink]="'/services/' + service">
                        <div class="services-image">
                            <img [src]="'assets/img/services-img'+service+'.jpg'" [alt]="'services.details.'+service+'.title' | translate" loading="lazy">
                            <div class="icon">
                                <i [class]="'services.details.'+service+'.icon' | translate"></i>
                            </div>
                        </div>
                        
                        <div class="services-content">
                            <h3>{{ 'services.details.'+service+'.title' | translate }}</h3>
                            <p [innerHtml]="'services.details.'+service+'.short_description' | translate"></p>
                        </div>
                    </a>
                </div>
            </div>
            
        </div>
    </div>
</section>