import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { EmailService } from 'src/app/services/email.service';
import { EmailService2 } from 'src/app/services/emailjs.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  message = {
    success: 'Success',
    loading: 'Loading...',
    error: 'Something went wrong'
  }

  contactFormGroup: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private emailService: EmailService2,
    private translate: TranslateService,    
    private toast: HotToastService) {
    this.contactFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      company: '',
      termsConditions: [false, Validators.requiredTrue],
      recaptcha: [false, Validators.requiredTrue],
      message: '',
    });
  }

  get contactFormControl() {
    return this.contactFormGroup.controls;
  }

  ngOnInit() {
    this.toast.defaultConfig.autoClose = true;
    this.toast.defaultConfig.dismissible = true;

    this.translate.get(['contact.toast.success', 'contact.toast.loading', 'contact.toast.error'])
    .subscribe(values => {
      // console.log('Contact2: ', values);
      
      if(values) {
        if (values['contact.toast.success']) {
          this.message.success = values['contact.toast.success'];
        }

        if (values['contact.toast.loading']) {
          this.message.loading = values['contact.toast.loading'];
        }        

        if (values['contact.toast.error']) {
          this.message.error = values['contact.toast.error'];
        }                
      }
    });    
  }

  resolved(captchaResponse: string): void {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.contactFormControl.recaptcha.setValue(captchaResponse.length > 0);
  }

  onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  onSubmit() {
    // console.log('contact:', this.contactFormGroup.value);
    // console.log('this.contactFormControl.recaptcha:', this.contactFormControl.recaptcha);

    this.submitted = true;
    if (this.contactFormGroup.valid && this.contactFormGroup.value) {
      let value = this.contactFormGroup.value;
      this.message.success = this.message.success.replace('{{email}}', value.email);
      this.message.loading = this.message.loading.replace('{{email}}', value.email);
      let error = this.message.error;
      
      this.emailService.sendContactEmail(this.contactFormGroup).then((email) => {
        console.log("Email: ", email);    
        this.toast.success(this.message.success);    
      }).catch((_) => {
        this.toast.error(this.message.error); 
      });
      // this.emailService.sendContactEmail(this.contactFormGroup)
      // .pipe(
      //   this.toast.observe({
      //     success: this.message.success,
      //     loading: this.message.loading,
      //     error(e: any) {     
      //       return error.replace('{{message}}', e)
      //     },
      //   })        
      // ).subscribe(res => {
      //   // console.log('res: ', res);
      // })
    }
  }

}
