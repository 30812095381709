import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

  services: any[] = [];

  constructor(private translate: TranslateService,
    private metaTagService: Meta) {
  }

  ngOnInit(): void {

    this.translate.get('meta.services').subscribe(values => {
      if (values) {
        const keys = Object.keys(values);
        if (keys.length > 0) {
          keys.forEach(key => {
            if (key.startsWith("og")) {
              this.metaTagService.updateTag({
                property: key,
                content: values[key]
              });
            } else {
              this.metaTagService.updateTag({
                name: key,
                content: values[key]
              });
            }
          })
        }
      }
    });
    
  }

}
